<template>
    <fixed-list :dataSource="dataSource" :config="config" @onDataSourceChange="onDataSourceChange" @onPageChange="initialize" :configurable="false" :queryParam="queryParam">
        <el-table slot="elList" ref="carrierListTable" :data="dataSource.Result" border @selection-change="handleSelectionChange" @current-change="handleCurrentChange" highlight-current-row>
            <el-table-column type="selection" width="55" v-if="config.isSelectionDisplay"></el-table-column>
            <el-table-column v-for="(col,index)  in dataSource.ColDefs.BodyFieldParams"
                             :key="index"
                             :prop="col.FieldName"
                             :label="col.DisplayName"
                             :render-header="bindFilter(queryParam,col)"
                             :fixed="index==0&&(!config||!config.isDetailDisplay)"
                             :min-width="(!config||!config.isDetailDisplay)?parseInt(col.Width):0"
                             v-if="col.Visible&&(!config||!config.isDetailDisplay||(config.isDetailDisplay&&index<2))">
                <template slot-scope="scope">
                    <el-button v-if="col.FieldName==='MemberCode'" type="text" style="color:#1874CD;cursor:pointer">{{ scope.row[col.FieldName] }}</el-button>
                    <span v-else>  {{ scope.row[col.FieldName] }}</span>
                </template>
            </el-table-column>
        </el-table>
    </fixed-list>
</template>
<script>
    export default {
        mounted() {
            this.initialize();//加载表格数据
        },
        data() {
            return {
                multipleSelection: [],
                queryParam: {
                    PageIndex: 1,
                    PageSize: 10,
                    Params: {}
                },
                dataSource: {
                    ColDefs: {},
                    Result: [],
                    TotalCount: 0,
                },
            }
        },
        props: {
            config: {
                isDetailDisplay: false,
                isSelectionDisplay: false,
            },
            selectCondition: {
            }
        },
        methods: {
            onChangeEditDataSource: function (data) {
                this.config.isDetailDisplay = true;
                this.$emit("onChangeEditDataSource", data);
            },
            onDataSourceChange(ds) {
                var _this = this;
                _this.dataSource = {
                    ColDefs: {
                        BodyFieldParams: []
                    },
                    Result: [],
                    TotalCount: 0
                };
                _this.$nextTick(function () {
                    _this.dataSource = ds;

                });
            },
            handleCurrentChange: function (val) {
                this.$emit("onSelectedRow", val);
            },
            handleSelectionChange(val) {
                this.multipleSelection = val;
                this.$emit("onSelectedRow", val);
            },
            initialize: function () {
                var _this = this;
                if (_this.selectCondition) {
                    _this.Utils.extend(_this.queryParam.Params, _this.selectCondition);
                } 
                this.$ajax.query("omsapi/sysstaff/getpagelist", "post", _this.queryParam, (result) => {
                    _this.dataSource = result;
                });
            },

        },

    }
</script>
